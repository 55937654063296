import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { NavBar } from "./components/layout/Navbar";
import { LoginPage } from "./components/login/LoginPage";
import { ForgotPassword } from './components/password/ForgotPasswordPage';
import { ResetPasswordPage } from './components/password/ResetPasswordPage';
import { UserListPage } from './components/users/user-list/UserListPage';
import { OnboardSuccessPage } from './components/home/OnboardSuccessPage';
import { AddCustomerPage } from './components/customer/AddCustomerPage';
import { CustomRouter } from './middleware/CustomRouter';
import { history } from './Utils/history';
import { Footer } from './components/layout/Footer';
import { PrivateRoute } from './middleware/PrivateRoute';
import { CustomerListPage } from './components/customer/CustomerListPage';
import { OnboardCustomerPage } from './components/home/OnboardCustomerPage';
import { AddUserPage } from './components/users/AddUserPage';
import { AccessDenied } from './components/home/AccessDenied';
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux';
import { userActions } from './actions/user.actions';
import { RegistrationPage } from './components/register/RegistrationPage';
import { DeletePage } from './components/users/DeletePage';
import { UserReport } from './components/users/user-report/UserReport';
import { UserScanReport } from './components/users/scan-report/UserScanReport';
import { UserScanReportDetail } from './components/users/scan-report-detail/UserScanReportDetail';


const timer = 1.8e+6;
export const eventHandler = (ev:any) => {
  ev.preventDefault();
  return ev.returnValue = 'Are you sure you want to close?';
}

function App() {
  window.addEventListener("beforeunload", eventHandler);
  window.addEventListener("contextmenu", e => e.preventDefault());
  const dispatch = useDispatch();
  const handleOnIdle = (event: any) => {
    if (getRemainingTime() === 0) {
      dispatch(userActions.logout());
    }
  }
  const { getRemainingTime } = useIdleTimer({
    timeout: timer,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true
    }
  });

  return (
    <div className="App">
      <div id="loader-cnt">
        <div id="loader-div"></div>
      </div>
      <div className='main-div'>
        <NavBar />
        <CustomRouter history={history}>
          <Routes>
            <Route path="/signin" element={<LoginPage />} />
            <Route path="/addUser" element={<PrivateRoute component={AddUserPage} path="addUser" />} />
            <Route path="/addCustomer" element={<PrivateRoute component={AddCustomerPage} path="addCustomer" />} />
            <Route path="/forgot-pwd" element={<ForgotPassword />} />
            <Route path="/reset-pwd" element={<ResetPasswordPage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/delete-user" element={<DeletePage />} />
            <Route path="search" element={<PrivateRoute component={OnboardCustomerPage} path="search" />} />
            <Route path="/viewUsers" element={<PrivateRoute component={UserListPage} path="viewUsers" />} />
            <Route path="/viewCustomers" element={<PrivateRoute component={CustomerListPage} path="viewCustomers" />} />
            <Route path="/viewLoginReport" element={<PrivateRoute component={UserReport} path="viewLoginReport" />} />
            <Route path="/viewScanReport" element={<PrivateRoute component={UserScanReport} path="viewScanReport" />} />
            <Route path="/viewScanReportDetail" element={<PrivateRoute component={UserScanReportDetail} path="viewScanReportDetail" />} />
            <Route path="/onboard-success" element={<OnboardSuccessPage />} />
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="/" element={<Navigate replace to="/signin" />} />
          </Routes>
        </CustomRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
