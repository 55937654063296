import './Navbar.scss';
import logoLattice from '../../assets/images/logo_lattice.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/user.actions';
import homeIcon from '../../assets/images/home-icon.svg';
import {history} from '../../Utils/history';
import { isLogin } from '../../middleware/Auth';
import { getUserRole } from '../../Utils/role-access';
import { eventHandler } from '../../App';
import { toPascalCase } from '../../Utils/helpers';

const NavBar = () => {
    const [userName, setUserName] = useState("");
    const loginState = useSelector((state: any) => state.user.loginState);
    const dispatch = useDispatch();
    const role = getUserRole();

    useEffect(() => {
        if (loginState && loginState.userName)
            setUserName(loginState.userName);
    }, [loginState]);

    const logOut = () => {
        dispatch(userActions.logout());
    }
    
    const homePage = () => {
        if(role === 'lattice_user' || role === 'customer_user'){ 
            window.removeEventListener("beforeunload", eventHandler);        
            window.location.reload(); 
        }
        // if(role === 'customer_super_user'){
        //     const path = window.location.pathname;
        //     if(path==="/search"){
        //         window.removeEventListener("beforeunload", eventHandler);
        //         window.location.reload();
        //     }
        // }
       history.push("/search");
    }
    const signInPage = () => {
        history.push("/signin");
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark py-3">
            <div className="container">
                {isLogin() ? <img src={logoLattice} alt="logo" className='cursor-pointer' width={190} height={32} onClick={homePage}/> : <img src={logoLattice} alt="logo" width={190} height={32} onClick={signInPage}/>}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {userName && isLogin() &&  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div className="navbar-nav ms-auto">
                        <button className="nav-link active" aria-current="page" onClick={homePage}><img className="mb-1 ic-home" src={homeIcon} alt=""></img></button>
                        <span className="nav-link  user-nm" >{toPascalCase(userName)}</span>
                        <button className="nav-link logout-link" onClick={logOut}>Sign Out</button>
                    </div>
                </div>}
            </div>
        </nav>
    )
}
export { NavBar };