import { Navigate } from "react-router";
import { getLandPageAccess } from "../Utils/role-access";
import { isLogin } from './Auth';
interface Props {
    component: React.ComponentType
    path: string
  }
  
  export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, path}) => {
    if (isLogin() && getLandPageAccess(path)) {
      return <RouteComponent />
    }
    return <Navigate to="/access-denied" />
  }