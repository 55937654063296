import './Style.css';
declare var bootbox: any;
const SuccessLoginModal = (props: any) => {
    bootbox.alert({
        message: `<h3 class=" status-cont text-success text-center mb-4">Success</h3>
        <h6 class="model-text text-secondary text-center m-0">`+props.message+`</h6>`,
        buttons: {
            ok: {
                label: props.ok,
                className: 'btn btn-primary ripple'
            }
        },
        closeButton: false,
        callback: function () {
            props.onValueChange();
        }
    });
};
export { SuccessLoginModal };
