import ReactPaginate from 'react-paginate';
import './Pagination.css';

const Pagination = (props: any) => {
    return (
        <div className="mb-3">
            <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                initialPage={props.initialPage}
                forcePage={props.initialPage}
                pageCount={props.pageCount}
                marginPagesDisplayed={1}  
                pageRangeDisplayed={3}
                onPageChange={props.handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"} />
        </div>
    );

}
export { Pagination };