import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions/user.actions';
import { between, getDate, getDateTime, getRoleDisplayName, sortAsc } from '../../../Utils/helpers';
import { getUserInfo } from '../../../Utils/role-access';
import { Pagination } from '../../common/pagination/Pagination';
import './UserReport.scss';
import { history } from '../../../Utils/history';
import { eventHandler } from '../../../App';
import { ErrorModal } from '../../common/confirm-modal/ErrorModal';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const UserReport = () => {
    const { userLoginReport } = useSelector((state: any) => state.user);
    const [usersexcel, setUsersExcel] = useState<Array<any>>([]);
    const [users, setUsers] = useState<Array<any>>([]);
    const [initialPage, setInitialPage] = useState(0);
    const [sort, setSort] = useState(false);
    const [listData, setListData] = useState<Array<any>>([]);
    const [sortFlag, setSortFlag] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [deviceType, setDeviceType] = useState('All');
    const [inputs, setInputs] = useState({
        startDate: '',
        endDate: '',
        deviceType: 'All'
    });
    const dispatch = useDispatch();

    const today = new Date().toISOString().split("T")[0];

    window.removeEventListener("beforeunload", eventHandler);

    useEffect(() => {
        setStartDate('');
        setEndDate('');
        setDeviceType('All');
    }, []);


    useEffect(() => {
        setUsers(userLoginReport.slice(0, 10));
        setInitialPage(0);
    }, [userLoginReport]);

    useEffect(() => {
        setUsersExcel(userLoginReport);
    }, [userLoginReport]);


    const onPageChange = (e: any) => {
        let { selected } = e;
        setInitialPage(selected);
        if (selected === 0) {
            if (sortFlag) {
                setUsers(listData.slice(0, 10));
            } else {
                setUsers(userLoginReport.slice(0, 10));
            }
        } else {
            let start = selected * 10;
            let end = (start+1) + 10;
            if (sortFlag) {
                let list: any = between(listData, start, end, false);
                setUsers(list);
            } else {
                let list: any = between(userLoginReport, start, end, false);
                setUsers(list);
            }
        }
    }


    const handleFilter = async () => {
        if (!startDate && !endDate) {
            ErrorModal({message:"Please select both Start Date and End Date! "});
            return;
        }
        else if(!startDate){
            ErrorModal({message:"Please select Start Date! "});
            return;
        }else if(!endDate){
            ErrorModal({message:"Please select End Date! "});
            return;
        }

        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        if(isNaN(startDateObj.getTime()) || isNaN(endDateObj.getDate())){
            ErrorModal({message:"Invalid date format.Please enter a valid date."});
            return;
        }

        const updatedInputs = ({
            ...inputs,
            startDate: startDateObj.toISOString().split("T")[0],
            endDate: endDateObj.toISOString().split("T")[0],
            deviceType: deviceType.toLowerCase(),
        });
        let userInfo: any = getUserInfo();
        let role = userInfo.role;
        if (role === "lattice_admin") {
            dispatch(userActions.getUsersLoginReport(updatedInputs));
        }
    };

    const handleDownload = () => {
        if(usersexcel.length === 0){
            ErrorModal({message: "No Data Available to Download!"});
            return;
        }

        const workSheetData = usersexcel.map((user: any) => ({
            "User Email": user.userEmail,
            "Customer Name": user.customerName,
            "Device Type": user.deviceType,
            "Country Name": user.countryName,
            "Login Count": user.loginCount,
        }));

        const workSheet = XLSX.utils.json_to_sheet(workSheetData);

        const columnWidths = Object.keys(workSheetData[0]).map((key) => 
        Math.max( key.length,...workSheetData.map((row) => String((row as any)[key]).length)) + 2);
        workSheet["!cols"] = columnWidths.map((width) => ({ wch: width }));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, workSheet, "UserLoginReport");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], {type: "application/octet-stream" });
        const currentDate = new Date().toISOString().split("T")[0];
        saveAs(blob, `UserLoginReport_${currentDate}.xlsx`);
    }

    return (
        <div className="table-cont">
            <div className="container-fluid">
                <h3 className='mt-3'>Users Login Report</h3>
                <div className="d-flex justify-content-between align-items-center mt-4">
                <div className="d-flex gap-3">
                <div>
                    <label htmlFor="startDate" className="form-label">Start Date</label>
                    <input
                        type="date"
                        id="startDate"
                        className="form-control"
                        value={startDate}
                        max={today}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="endDate" className="form-label">End Date</label>
                    <input
                        type="date"
                        id="endDate"
                        className="form-control"
                        value={endDate}
                        max={today}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="deviceType" className="form-label">Device Type</label>
                    <select
                        id="deviceType"
                        className="form-select"
                        value={deviceType}
                        onChange={(e) => setDeviceType(e.target.value)}
                    >
                        <option value="All">All</option>
                        <option value="Android">Android</option>
                        <option value="iOS">iOS</option>
                        <option value="Web">Web</option>
                    </select>
                </div>
                <div className='d-flex gap-3 justify-content-between align-items-center mt-3'>
                    <button className="btn btn-primary"
                        onClick={handleFilter}>
                        Get Report
                    </button>
                </div>
            </div>
            <button className='btn btn-primary mt-3'
                    onClick={handleDownload}
                    disabled={users.length === 0 }>
                    <i className="fas fa- download"></i> Download Report
            </button>
        </div>    
                <div className="d-flex justify-content-center mt-4">
                    <table className="table maint-table">
                        <thead className="table-dark">
                            <tr>
                                <td>S_NO</td>
                                <td>USER EMAIL</td>
                                <td>CUSTOMER NAME</td>
                                <td>DEVICE TYPE</td>
                                <td>COUNTRY NAME</td>
                                <td>LOGIN COUNT</td>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length === 0 &&  <tr><td colSpan={7}><h6 className="text-center m-3">No Data Available </h6></td></tr>}
                            {users.map((row: any, index: number) => {
                                return (
                                    <tr key={index} >
                                        <td> {row.s_No}</td>
                                        <td> {row.userEmail}</td>
                                        <td> {row.customerName}</td>
                                        <td> {row.deviceType}</td>
                                        <td> {row.countryName}</td>
                                        <td> {row.loginCount}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pagination-cont mt-2">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            <Pagination pageCount={Math.ceil((userLoginReport.length / 10))} handlePageClick={onPageChange} forcePage={initialPage} initialPage={initialPage} />
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export { UserReport }
