import { userConstants } from "../constants/user.constants";
const loginUser: any = localStorage.getItem("user");
const initialState = {
    result: {},
    addUserState: {},
    loginState: (loginUser !== null) ? JSON.parse(loginUser) : {},
    userList: [],
    customerList: [],
    customerNames: [],
    userLoginReport: [],
    userScanReport: [],
    userScanReportDetail: [],
    // lotIdList: []

};

export function user(state = initialState, action: any) {
    switch (action.type) {
        case userConstants.POST_USERLOGIN_REQUEST:
            return { ...state };
        case userConstants.POST_USERLOGIN_SUCCESS:
            return { ...state, loginState: action.response.data.result };
        case userConstants.POST_USERLOGIN_FAILURE:
            return { ...state };
        case userConstants.POST_USERREG_REQUEST:
            return { ...state };
        case userConstants.POST_USERREG_SUCCESS:
            return { ...state, registerState: action.response };
        case userConstants.POST_USERREG_FAILURE:
            return { ...state };

        case userConstants.SAVE_USER_REQUEST:
            return { ...state };
        case userConstants.SAVE_USER_SUCCESS:
            return { ...state, addUserState: action.response };
        case userConstants.SAVE_USER_FAILURE:
            return { ...state };


        case userConstants.LOGOUT_REQUEST:
            return { ...state };
        case userConstants.LOGOUT_SUCCESS:
            return { ...state, loginState: {} };
        case userConstants.LOGOUT_FAILURE:
            return { ...state };
        case userConstants.GET_USERLIST_REQUEST:
            return { ...state };
        case userConstants.GET_USERLIST_SUCCESS:
            return {
                ...state,
                userList: action.response.data.result
            };
        case userConstants.GET_USERLIST_FAILURE:
            return { ...state };
        case userConstants.GET_CUSTOMERLIST_REQUEST:
            return { ...state };
        case userConstants.GET_CUSTOMERLIST_SUCCESS:
            return {
                ...state,
                customerList: action.response.data.result
            };
        case userConstants.GET_CUSTOMERLIST_FAILURE:
            return { ...state };
        case userConstants.GET_CUSTOMERNAME_REQUEST:
            return { ...state };
        case userConstants.GET_CUSTOMERNAME_SUCCESS:
            return { ...state, customerNames: action.response.data.result };
        case userConstants.GET_CUSTOMERNAME_FAILURE:
            return { ...state };
        case userConstants.SEARCH_REQUEST:
            return { ...state, result: {} };
        case userConstants.SEARCH_SUCCESS:
            return { ...state, result: action.response.data.result };
        case userConstants.SEARCH_FAILURE:
            return { ...state };

        case userConstants.GET_USERLOGINREPORT_REQUEST:
            return { ...state };
        case userConstants.GET_USERLOGINREPORT_SUCCESS:
            return { ...state, userLoginReport: action.response.data.result };
        case userConstants.GET_USERLOGINREPORT_FAILURE:
            return { ...state };

        case userConstants.GET_USERSCANREPORT_REQUEST:
            return { ...state };
        case userConstants.GET_USERSCANREPORT_SUCCESS:
            return { ...state, userScanReport: action.response.data.result };
        case userConstants.GET_USERSCANREPORT_FAILURE:
            return { ...state };  
            
        case userConstants.GET_USERSCANREPORTDETAIL_REQUEST:
            return { ...state, 
                isLoading: true,  
                error: null
            };
        case userConstants.GET_USERSCANREPORTDETAIL_SUCCESS:
            return { ...state, 
                userScanReportDetail: action.response.data.result, 
                isLoading: false    
            };
        case userConstants.GET_USERSCANREPORTDETAIL_FAILURE: 
            return { ...state, 
                isLoading: false,  
                error: action.error
            };  
        // case userConstants.GET_LOTIDLIST_REQUEST:
        //     return { ...state };
        // case userConstants.GET_LOTIDLIST_SUCCESS:
        //     return {
        //         ...state,
        //         lotIdList: action.response.data.result
        //     };
        // case userConstants.GET_LOTIDLIST_FAILURE:
        //     return { ...state };
        default:
            return state
    }
}