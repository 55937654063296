

const CustomerObj = {
    customerName: "",
    address1: "",
    address2: "",
    address3: "",
    country: "",
    state: "",
    zipcode: "",
    status: "",
    id: ""
}
const PasswordObj = {
    newPassword: "",
    confirmPassword: "",
    currentPassword: "",
    email: "",
    customer: "",
    text: ""
}

const UserObj = {
    role: "",
    userEmail: "",
    userName: "",
    customerId: "",
    status: "",
    id: ""
}

const RegisterObj = {
    role: "customer_user",
    userEmail: "",
    userName: "",
    customerName: "",
    status: 1
}

const roleList = [{ role: "Customer User", name: "customer_user" }, { role: "Lattice User", name: "lattice_user" }, { role: "Lattice Admin", name: "lattice_admin" }];

const status = [{ status: 1, label: "Active" }, { status: 0, label: "Inactive" }];

const SearchResultTableHeading = ["PRODUCT NAME", "PRODUCT LABEL", "PACKAGE/PIN", "SPEED/GARDE", "ILF", "ASSY SITE", "DATE CODE"];
// const genericIDHeadings = [{ label: "LOT ID", value: "lotId" }, { label: "PRODUCT LABEL", value: "productLabel" }, { label: "PRODUCT CODE", value: "productCode" }, { label: "PACKAGE CODE", value: "packageCode" }, { label: "SPEED GRADE", value: "speedGrade" }, { label: "MSL/PEAK TEMP MAX", value: "msl" }, { label: "ASSY SITE", value: "assySite" }, { label: "DATE CODE", value: "labelDateCode" }];
// const lotIdSearchHeadings = [{ label: "LOT ID", value: "lotId" }, { label: "PRODUCT CODE", value: "productCode" }, { label: "PACKAGE CODE", value: "packageCode" }, { label: "MSL/PEAK TEMP MAX", value: "msl" }, { label: "ASSY SITE", value: "assySite" }, { label: "DATE CODE", value: "labelDateCode" }];
const genericIDHeadings = [{ label: "2D Code ID", value: "unitcode" }, { label: "Lot ID", value: "lotId" }, { label: "Product Label", value: "productLabel" }, { label: "Product Code", value: "productCode" }, { label: "Package Code", value: "packageCode" }, { label: "Speed Grade", value: "speedGrade" }, { label: "MSL/Peak Temp Max", value: "msl" }, { label: "Assy Site", value: "assySite" }, { label: "Date Code", value: "labelDateCode" }];
const lotIdSearchHeadings = [{ label: "Lot ID", value: "lotId" }, { label: "Product Code", value: "productCode" }, { label: "Package Code", value: "packageCode" }, { label: "MSL/Peak Temp Max", value: "msl" }, { label: "Assy Site", value: "assySite" }, { label: "Date Code", value: "labelDateCode" }];
export { roleList, SearchResultTableHeading, PasswordObj, CustomerObj, status, UserObj, genericIDHeadings, lotIdSearchHeadings, RegisterObj };

