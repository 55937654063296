import moment from "moment";
import { roleList } from "./constants";

const CryptoJS = require("crypto-js");
let key = CryptoJS.enc.Latin1.parse('1234567812345678');
let iv = CryptoJS.enc.Latin1.parse('1234567812345678');

const isEmptyStr = (str: string) => {
    if (str === "") {
        return true;
    } else {
        return false;
    }
}

const generateKey = () => {
    return Math.floor(Math.random() * 100000);
}

const sortAsc = (list: any, value: any, action: any) => {
    return list.sort((first: any, second: any) => {
        let a = (first[value] || '').toLowerCase(), b = (second[value] || '').toLowerCase() || '';
        return a === b ? 0 : (action ? a > b : b > a) ? -1 : 1;
    });
}


const getDate = (date: any) => {
    return moment(date).format('ll');
}

const getDateTime = (date: any) => {
    let lastLogin: any = moment(date).format('lll');
    return lastLogin !== 'Invalid date' ? lastLogin : "-";
}

const between = (arr: Array<any>, from: number, to: number, inclusive = true) => {
    let result = [];
    if (inclusive) {
        result = arr.filter((x, i) => {
            if (i >= (from - 1) && i <= (to - 1)) { return true }
            else { return false }
        })
    }
    if (!inclusive) {
        result = arr.filter((x, i) => {
            if (i > (from - 1) && i < (to - 1)) { return true }
            else { return false }
        })
    }
    return result;
}

const encryptText = (msg: string) => {
    let encrypted = CryptoJS.AES.encrypt(
        msg,
        key,
        {
            iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding
        });
    return encrypted.toString();
}

const decryptText = (msg: string) => {
    msg = decodeURIComponent(msg.toString());
    let decrypted = CryptoJS.AES.decrypt(
        msg,
        key,
        {
            iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
        });
    return (decrypted.toString(CryptoJS.enc.Utf8)).trim();
}

const getRoleDisplayName = (name: string) => {
    let data: any = roleList.find((row: any) => row.name === name)
    return data?.role;
}
const getUserEmailAndCustomer = (qry: any) => {
    if (qry) {
        let splitStr = qry.split("?");
        if (splitStr.length === 3) {
            return { email: splitStr[0], customer: splitStr[1], text: splitStr[2] };
        } else {
            return { email: "", customer: "" };
        }
    } else {
        return { email: "", customer: "" };
    }

}

const searchValidate = (id : any) => {
    if(id.includes("%")|| id.includes("/")){
        return true;
    }else{
        return false;
    }
}

const toPascalCase = (username:any) => username.split(' ').map((word:any) => word[0].toUpperCase().concat(word.slice(1))).join(' ');

export { isEmptyStr, generateKey, sortAsc, between, encryptText, decryptText, getRoleDisplayName, getDateTime, getDate, getUserEmailAndCustomer, toPascalCase, searchValidate };