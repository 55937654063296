
import { AxiosResponse } from 'axios';
import { ErrorModal } from '../components/common/confirm-modal/ErrorModal';
import { SuccessLoginModal } from '../components/common/confirm-modal/SuccessLoginModal';
import { passwordConstants } from '../constants/password.constants';
import { fetch } from '../Utils/api-route';
import { encryptText } from '../Utils/helpers';
import { history } from '../Utils/history';

export const passwordActions = {
    forgotUser,
    changePassword
};

function forgotUser(email: any) {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'v1/password/reset',
            data: email
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
                SuccessLoginModal({
                    message: "Default password sent to your registered email", ok: "Sign In", onValueChange: function onValueChange() {
                        history.push("/signin");
                    }
                });
            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            // ErrorModal(error);
            ErrorModal({message:"Network Error !"});
        });

    };
    function request() { return { type: passwordConstants.POST_FORGOTPWD_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: passwordConstants.POST_FORGOTPWD_SUCCESS, response } }
    function failure(error: any) { return { type: passwordConstants.POST_FORGOTPWD_FAILURE, error } }
}

function changePassword(data: any) {
        fetch({
            method: 'post',
            url: 'v1/password/change',
            data: {userEmail:data.email,newPassword:encryptText(data.newPassword),currentPassword:encryptText(data.currentPassword)}
        }).then(function (response:any) {
            if (response.data.status === 200) {
                SuccessLoginModal({
                    message: "Password Changed Successfully", ok: "Sign In", onValueChange: function onValueChange() {
                        history.push("/signin");
                    }
                });
            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            ErrorModal(error);
        });
}
