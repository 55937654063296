import './TnCStyle.css';

declare var bootbox: any;
const TnCModal = () => {    
const data:any=process.env.REACT_APP_TERMS_CONDITIONS;
    bootbox.alert({
        title: `<h4 classname="modal-title">Terms and Conditions</h4>`,
        message: `<h5 class="">`+data+`</h5>`,
        size: 'extra-large'
    });
};
export { TnCModal };
