import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PasswordObj } from '../../Utils/constants';
import { decryptText, getUserEmailAndCustomer } from '../../Utils/helpers';
import { validateAll, validateData } from '../login/validation';
import { passwordActions } from '../../actions/password.actions';

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState(PasswordObj);
    const [error, setErrors] = useState(Object.create(PasswordObj));
    const [searchParams] = useSearchParams();
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setPassword({ ...password, [name]: value });
        let errorObj: any = error;
        validateData(name, value, errorObj, password);
        setErrors((state: any) => ({ ...state, errorObj }));
    }
    useEffect(()=>{
        const urlInfo = getUserEmailAndCustomer(searchParams.get('user'));
        if(urlInfo){
            setPassword((state: any) => ({...state, customer:decryptText(urlInfo.customer),email:decryptText(urlInfo.email),text:(urlInfo.text)}));
        }
    },[searchParams]);

    const onCancel = () => {
        navigate("/signin");
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let errorObj = error;
        const isValid = validateAll(password, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
        if (isValid) {
           passwordActions.changePassword(password);
        }
    }

    return (
        <div className="form-img py-5">
            <div className='card add-form-cont col-md-6 p-4 '>
            <h3 className="d-flex justify-content-center mt-4">{password.text==="reset" ? "Reset Password" : "Change Password"}</h3>
                <div className="col-md-12 mt-3">
                    <form name="form" className="p-3">
                        <div className="form-group">
                            <label className='form-label'>Current Password</label>
                            <input type="password" name="currentPassword" value={password.currentPassword} onChange={handleChange} placeholder="Enter Current Password" className={'form-control' + (error.currentPassword ? ' is-invalid' : '')} />
                            {error.currentPassword &&
                                <div className="invalid-feedback">{error.currentPassword}</div>
                            }
                        </div>
                        <div className="form-group">
                            <label className='form-label'>New Password</label>
                            <input type="password" name="newPassword" value={password.newPassword} onChange={handleChange} placeholder="Enter New Password" className={'form-control' + (error.newPassword ? ' is-invalid' : '')} />
                            {error.newPassword &&
                                <div className="invalid-feedback">{error.newPassword}</div>
                            }
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Confirm Password</label>
                            <input type="password" name="confirmPassword" value={password.confirmPassword} onChange={handleChange} placeholder="Enter Confirm Password" className={'form-control' + (error.confirmPassword ? ' is-invalid' : '')} />
                            {error.confirmPassword &&
                                <div className="invalid-feedback">{error.confirmPassword}</div>
                            }
                        </div>

                        <div className="button-group mt-5 d-flex justify-content-center">
                            <button className="btn btn-outline-primary mx-3" onClick={onCancel}>Cancel</button>
                            <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { ResetPasswordPage }
