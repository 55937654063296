import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { userActions } from '../../actions/user.actions';
import './LoginPage.scss';
import { validateAll, validateData } from './validation';
import { isLogin } from '../../middleware/Auth';
import { TnCModal } from '../common/confirm-modal/TnCModal';
import { ErrorModal } from '../common/confirm-modal/ErrorModal';
import { LocationModal } from '../common/confirm-modal/LocationModal'; // Import the custom modal
import { eventHandler } from '../../App';

 
function LoginPage() {
    const [inputs, setInputs] = useState({
        userEmail: '',
        password: '',
        deviceType: 'web',
        latitude: '',
        longitude: ''

    });
    const [error, setError] = useState({ userEmail: '', password: '' });
    const { userEmail, password } = inputs;
    const [agree, setAgree] = useState(false);
    const [locationFetched, setLocationFetched] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false); 
    const dispatch = useDispatch();
 
    window.removeEventListener('beforeunload', eventHandler);
 
    useEffect(() => {
        if (isLogin()) {
            dispatch(userActions.logout());
        }

        const requestLocation = () => {

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setInputs((inputs) => ({
                                ...inputs,
                                latitude: position.coords.latitude.toString(),
                                longitude: position.coords.longitude.toString(),
                        }));
                        setLocationFetched(true);
                        setShowLocationModal(false); 
                        
                    },
                    (error) => {
                        setLocationFetched(false);
                        setShowLocationModal(true); 
                    }
                );
            } else {
                setLocationFetched(false);
                ErrorModal({ message: 'Geolocation is not supported by this browser.' });
            }
        };
 
        requestLocation(); 
 
    }, [dispatch]);
 
    function handleChange(e: { target: { name: any; value: any; }; }) {
        const { name, value } = e.target;
        setInputs((inputs) => ({ ...inputs, [name]: value }));
        let errorObj = error;
        validateData(name, value, errorObj);
        setError((state) => ({ ...state, errorObj }));
    }
 
    function handleSubmit(e: { preventDefault: () => void; }) {
        e.preventDefault();
        let errorObj = error;
        const isValid = validateAll(inputs, errorObj);
        setError((state) => ({ ...state, errorObj }));
        if (isValid) {
            if (agree) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const updatedInputs = ({
                                ...inputs,
                                latitude: position.coords.latitude.toString(),
                                longitude: position.coords.longitude.toString(),
                            });
                            setLocationFetched(true);
                            dispatch(userActions.login(updatedInputs));
                            setShowLocationModal(false); 
                            
                        },
                        (error) => {
                            setLocationFetched(false);
                            setShowLocationModal(true); 
                        }
                    );
                } else {
                    setLocationFetched(false);
                    ErrorModal({ message: 'Geolocation is not supported by this browser.' });
                }
            } else {
                ErrorModal({ message: 'Please accept terms and conditions' });
            }
        }
    }
 
    

    const checkboxHandler = () => {
        setAgree(!agree);
    };
 
    const handleTerms = () => {
        TnCModal();
    };
 
 
    const cancelLocation = () => {
        setShowLocationModal(false);
        window.location.reload();
    };
 
    return (
        <div className='login-form-cont'>
            <div className='col-md-4 offset-md-7 py-4'>
                <div className='login-bg-card'>
                    <h2 className='search-h2'>Lattice FPGA<br/>Lot Search Portal</h2>
                    <div className='d-flex justify-content-center card'>
                        <h2 className='d-flex justify-content-center mt-4'>Sign In to Your Account</h2>
                        <form name='form' className='p-4' onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label className='form-label'>Email Address</label>
                                <input type='text' name='userEmail' value={userEmail} onChange={handleChange} className={'form-control' + (error.userEmail ? ' is-invalid' : '')} />
                                {error.userEmail && <div className='invalid-feedback'>{error.userEmail}</div>}
                            </div>
                            <div className='form-group'>
                                <label className='form-label'>Password</label>
                                <input type='password' name='password' value={password} onChange={handleChange} className={'form-control' + (error.password ? ' is-invalid' : '')} />
                                {error.password && <div className='invalid-feedback'>{error.password}</div>}
                            </div>
                            <div className='form-group'>
                                <input type='checkbox' className='form-check-input' onChange={checkboxHandler} />
                                <label className='p-2'>
                                    Agree to <span className='term-label' onClick={handleTerms}>Terms and Conditions</span>
                                </label>
                            </div>
                            <div className='button-group mt-3'>
                                <button className="btn btn-primary w-100">Sign In</button>
                                <Link to='/forgot-pwd' className='d-flex justify-content-center btn btn-link mt-2 forgot-pwd'>Forgot Password?</Link>
                            </div>
                            <div>
                                <label className='d-flex justify-content-center mt-1'>
                                    Need an account? <Link to='/register' className='px-2 term-label'>Register</Link>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
 
            
            <LocationModal
                show={showLocationModal}
                onCancel={cancelLocation}
            />
        </div>
    );
}
 
export { LoginPage };