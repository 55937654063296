import { passwordConstants } from "../constants/password.constants";
const initialState = {
   result:{}
};

export function password(state = initialState, action: any) {
    switch (action.type) {
        case passwordConstants.POST_FORGOTPWD_REQUEST:
            return { ...state };
        case passwordConstants.POST_FORGOTPWD_SUCCESS:
            return { ...state, result: action.response };
        case passwordConstants.POST_FORGOTPWD_FAILURE:
            return { ...state };    
        default:
            return state
    }
}