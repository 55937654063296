import './Footer.scss';
const Footer = () => {
    return (
        <footer className="footer mt-auto py-3">
            <div className="footer-div">
                <span className="text-muted mt-4">&copy;2022 Lattice Semiconductor Corporation</span>
            </div>
        </footer>
    )
}
export { Footer };