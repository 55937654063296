import { isEmptyStr } from "../../Utils/helpers";
require('dotenv').config();

/* eslint-disable no-useless-escape */
const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@*|\/"{}`\\()'=!+^%#&$?~\"]+(\.[^<>()\[\]\.,;:\s@*|\/"{}`\\()'=!+^%#&$?~\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const passwordRegex = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/);
const noSpecial = /^[^*|\/":<>[\]{}`\\()';=!+^%#@&$?~_-]+$/;
const noSpecialUser = /^[^*|\/":<>[\]{}`\\()';=!+^%.,#@&$?~_-]+$/;
const noDigits = /^([^0-9]*)$/;
const emailDomains: any = process.env.REACT_APP_IGNORE_EMAIL?.split(","); //["gmail",'yahoo','hotmail','aol','msn','wanadoo','live','rediffmail','free','facebook','voila','mail','freenet','gmx','mac','skynet','hetnet','sky','icloud','me'];

const validateEmail = (email: any) => {
    if (isEmptyStr(email)) {
        return 'Email address is required.';
    } 
    else if (!validEmailRegex.test(email)) {
        return 'Please enter valid email address.';
    } 
    else {
        let emailArr = email.split('@');
        if (emailArr.length === 2) {
            let substr: Array<any> = emailArr[1].split(".");
            if (substr.length && emailDomains.includes(substr[0].toLowerCase())) {
                return 'Please enter valid domain name.';
            }
        }
    }
}

const validatePassword = (pwd: any, key: string, customer?: string) => {
    let msg = "Password is required.";
    if (key === 'currentPassword') {
        msg = "Current password is required."
    } else if (key === 'newPassword') {
        msg = "New password is required."
    }
    if (isEmptyStr(pwd)) {
        return msg;
    } else if (pwd.length < 12) {
        return "Password length must be at least 12 or more, non-alphanumeric, at least one digit, one uppercase letter and one lowercase letter.";
    } else if (!passwordRegex.test(pwd)) {
        return "Password must be non alphanumeric,at least one digit,one uppercase letter and one lowercase letter.";
    } else if (customer && pwd.includes(customer)) {
        return "Password should not include Customer Name."
    }
}

const validateName = (customerName: any) => {
    if (isEmptyStr(customerName.trim())) {
        return 'Company Name is required.';
    } else if (!noSpecial.test(customerName)) {
        return 'Special characters are not allowed.';
    }

}
const validateCustomerId = (id: any) => {
    if (isEmptyStr(id)) {
        return 'Company Name is required.';
    }
}
const validateAddress1 = (address1: any) => {
    if (isEmptyStr(address1)) {
        return 'Address1 is required.';
    }
}

const validateAddress2 = (address2: any) => {
    if (isEmptyStr(address2)) {
        return 'Address2 is required.';
    }
}

const validateCountry = (country: any) => {
    if (isEmptyStr(country)) {
        return 'Country is required.';
    }
}

const validateZipcode = (zipcode: any) => {
    const re = /^[0-9\b]+$/;
    if (isEmptyStr(zipcode)) {
        return 'ZipCode is required.';
    } else if (!re.test(zipcode)) {
        return 'Zipcode must be numeric.'
    } else if (zipcode.length < 4 || zipcode.length > 6) {
        return 'Zipcode length must be between 4 to 6';
    }

}
const validateStatus = (status: any) => {
    if (isEmptyStr(status)) {
        return 'Status is required.';
    }
}

const validateUserName = (name: any) => {
    if (isEmptyStr(name.trim())) {
        return 'User name is required.';
    } else if (!noSpecialUser.test(name)) {
        return 'Special characters are not allowed.';
    } else if (!noDigits.test(name)) {
        return 'Number is not allowed.';
    }
}

const validateRole = (role: any) => {
    if (isEmptyStr(role)) {
        return 'User type is required.';
    }
}
const confirmPassword = (newPwd: string, pwd: string) => {
    if (isEmptyStr(pwd)) {
        return 'Confirm password is required.';
    } else if (pwd !== newPwd) {
        return 'Confirm password and new password must be same.';
    }
}


const validateData = (name: string, value: string, error: any, state?: any) => {
    switch (name) {
      case 'userEmail':
            error.userEmail = validateEmail(value);
            break;
        case 'password':
            error.password = validatePassword(value, name);
            break;
        case 'customerName':
            error.customerName = validateName(value);
            break;
        case 'customerId':
            error.customerId = validateCustomerId(value);
            break;
        case 'address1':
            error.address1 = validateAddress1(value);
            break;
        case 'address2':
            error.address2 = validateAddress2(value);
            break;
        case 'zipcode':
            error.zipcode = validateZipcode(value);
            break;
        case 'status':
            error.status = validateStatus(value);
            break;
        case 'country':
            error.country = validateCountry(value);
            break;
        case 'userName':
            error.userName = validateUserName(value);
            break;
        case 'role':
            error.role = validateRole(value);
            break;
        case 'confirmPassword':
            error.confirmPassword = confirmPassword(state['newPassword'], value);
            break;
        case 'newPassword':
            error.newPassword = validatePassword(value, name, state['customer']);
            break;
        case 'currentPassword':
            error.currentPassword = validatePassword(value, name);
            break;
        default:
            break;
    }
}
const validateAll = (state: any, error: any) => {
    let valid = true;
    for (const key in state) {
        validateData(key, state[key], error, state);
        if (error[key]) {
            valid = false;
        }
    }
    return valid;
}

export { validateData, validateAll };