import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions/user.actions';
import { between, getDate, getDateTime, getRoleDisplayName, sortAsc } from '../../../Utils/helpers';
import { getUserInfo } from '../../../Utils/role-access';
import editIicon from '../../../assets/images/ic-edit.svg';
import sortIcon from '../../../assets/images/ic-sort.svg';
import { Pagination } from '../../common/pagination/Pagination';
import './UserListPage.scss';
import { history } from '../../../Utils/history';
import { eventHandler } from '../../../App';

const UserListPage = () => {
    const { userList } = useSelector((state: any) => state.user);
    const [users, setUsers] = useState<Array<any>>([]);
    const [initialPage, setInitialPage] = useState(0);
    const [sort, setSort] = useState(false);
    const [listData, setListData] = useState<Array<any>>([]);
    const [sortFlag, setSortFlag] = useState(false);
    const dispatch = useDispatch();

    window.removeEventListener("beforeunload", eventHandler);

    useEffect(() => {
        let userInfo: any = getUserInfo();
        let role = userInfo.role;
        if (role === "lattice_admin") {
            dispatch(userActions.getUsers());
        } 
        // else {
        //     dispatch(userActions.getUsersById(userInfo.customerId));
        // }
    }, [dispatch]);

    // useEffect(() => {
    //     setUsers(userList);
    // }, [userList]);


    useEffect(() => {
        setUsers(userList.slice(0, 10));
        setInitialPage(0);
    }, [userList]);

    const onPageChange = (e: any) => {
        let { selected } = e;
        setInitialPage(selected);
        if (selected === 0) {
            if (sortFlag) {
                setUsers(listData.slice(0, 10));
            } else {
                setUsers(userList.slice(0, 10));
            }
        } else {
            let start = selected * 10;
            let end = (start+1) + 10;
            if (sortFlag) {
                let list: any = between(listData, start, end, false);
                setUsers(list);
            } else {
                let list: any = between(userList, start, end, false);
                setUsers(list);
            }
        }
    }

    const handleEdit = (id: string) => {
        history.push("/addUser", { id: id });
    }

    const handleSort = () => {
        setSortFlag(true);
        setSort(!sort);
        let data: any = sortAsc([...userList], 'customerName', sort);
        setListData(data);
        if (initialPage === 0) {
            // let list = data.slice(0,10);
            setUsers(data.slice(0, 10));
        } else {
            let start = initialPage * 10;
            let end = (start+1) + 10;
            let list: any = between(data, start, end, false);
            setUsers(list);
        }
    }

    return (
        <div className="table-cont">
            <div className="container-fluid">
                <h3 className='mt-3'>View / Modify Users</h3>
                <div className="d-flex justify-content-center mt-4">
                    <table className="table maint-table">
                        <thead className="table-dark">
                            <tr>
                                <td>CUSTOMER<img src={sortIcon} className='sort-icon' alt="" onClick={handleSort}></img></td>
                                <td className='tablew100'>CUSTOMER ID</td>
                                <td>ROLE</td>
                                <td>USERNAME</td>
                                <td>EMAIL</td>
                                <td>CREATED BY</td>
                                <td>CREATED DATE</td>
                                <td>LAST SIGN IN</td>
                                <td>STATUS</td>
                                <td>ACTIONS</td>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length === 0 && <tr><td colSpan={7}><h6 className="text-center m-3">No Data Available</h6></td></tr>}
                            {users.map((row: any, index: number) => {
                                return (
                                    <tr key={index} >
                                        <td> {row.customerName}</td>
                                        <td  className='tablew100'> {row.customerId}</td>
                                        <td> {getRoleDisplayName(row.role)}</td>
                                        <td> {row.userName}</td>
                                        <td> {row.userEmail}</td>
                                        <td> {row.createdBy}</td>
                                        <td> {getDate(row.creationDate)}</td>
                                        <td> {getDateTime(row.lastLogin)}</td>
                                        <td>
                                            {row.status === 1 && <div className='btn btn-success btn-sm'>Active</div>}
                                            {row.status === 0 && <div className="btn btn-danger btn-sm">Inactive</div>}
                                        </td>
                                        <td>
                                            {row.customerStatus === 1 && <img className="imgHover" src={editIicon} alt="edit" onClick={() => handleEdit(row.id)}></img>}
                                            {row.customerStatus === 0 && <img className="imgHover-disabled" title="Customer is not active" src={editIicon} alt="edit"></img>}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pagination-cont mt-2">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            <Pagination pageCount={Math.ceil((userList.length / 10))} handlePageClick={onPageChange} forcePage={initialPage} initialPage={initialPage} />
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export { UserListPage }
