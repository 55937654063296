
import './Style.css';
import ErrorIcon from '../../../assets/images/ic_error.svg';
declare var bootbox: any;
 
const ErrorModalSearch = (props: any) => {
    const dialog =bootbox.dialog({
        message: `<img class="rounded mx-auto d-block" src=`+ErrorIcon+` alt="Error"/>
<div align="Center" style="width:280px"><br>`+"           "+props.message+"           "+ `<br><br>`+ "Contact Sales - sales@latticesemi.com "   +`</div>`,

                
                  
        closeButton: false
    });
    setTimeout(function(){
        dialog.modal('hide');
    }, 3000);
};
export {ErrorModalSearch};