const userConstants = {
    GET_USERINFO_REQUEST: "GET_USERINFO_REQUEST",
    GET_USERINFO_SUCCESS: "GET_USERINFO_SUCCESS",
    GET_USERINFO_FAILURE: "GET_USERINFO_FAILURE",

    POST_USERREG_REQUEST: "POST_USERREG_REQUEST",
    POST_USERREG_SUCCESS: "POST_USERREG_SUCCESS",
    POST_USERREG_FAILURE: "POST_USERREG_FAILURE",

    SAVE_USER_REQUEST: "SAVE_USER_REQUEST",
    SAVE_USER_SUCCESS: "SAVE_USER_SUCCESS",
    SAVE_USER_FAILURE: "SAVE_USER_FAILURE",

    POST_USERLOGIN_REQUEST: "POST_USERLOGIN_REQUEST",
    POST_USERLOGIN_SUCCESS: "POST_USERLOGIN_SUCCESS",
    POST_USERLOGIN_FAILURE: "POST_USERLOGIN_FAILURE",

    GET_USERLIST_REQUEST: "GET_USERLIST_REQUEST",
    GET_USERLIST_SUCCESS: "GET_USERLIST_SUCCESS",
    GET_USERLIST_FAILURE: "GET_USERLIST_FAILURE",

    GET_CUSTOMERLIST_REQUEST: "GET_CUSTOMERLIST_REQUEST",
    GET_CUSTOMERLIST_SUCCESS: "GET_CUSTOMERLIST_SUCCESS",
    GET_CUSTOMERLIST_FAILURE: "GET_CUSTOMERLIST_FAILURE",

    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",

    GET_CUSTOMERNAME_REQUEST: "GET_CUSTOMERNAME_REQUEST",
    GET_CUSTOMERNAME_SUCCESS: "GET_CUSTOMERNAME_SUCCESS",
    GET_CUSTOMERNAME_FAILURE: "GET_CUSTOMERNAME_FAILURE",

    SEARCH_REQUEST: "SEARCH_REQUEST",
    SEARCH_SUCCESS: "SEARCH_SUCCESS",
    SEARCH_FAILURE: "SEARCH_FAILURE",

    // GET_LOTIDLIST_REQUEST: "GET_LOTIDLIST_REQUEST",
    // GET_LOTIDLIST_SUCCESS: "GET_LOTIDLIST_SUCCESS",
    // GET_LOTIDLIST_FAILURE: "GET_LOTIDLIST_FAILURE"
}

export { userConstants }