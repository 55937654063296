import { getUserRole } from "../../Utils/role-access";
const getCustomerAccess=(userType:string)=>{
    let role = getUserRole();
    if(role === 'lattice_admin' && userType.toLowerCase().includes("lattice")){
         return true;
    }else if(role !== "lattice_admin"){
        return true;
    }
    else {
         return false;
    }
}

const userValidation=()=>{
    let role = getUserRole();
    if(role === 'lattice_admin'){
        return false;
    }else{
        return  true;
    }
}
const filterCustomerList=(arr:any)=>{
    const customerList = arr.filter((row:any)=>{
        return row.customerName.toLowerCase() !== 'lattice semiconductor';
    });
    return customerList;
}

const  isLatticeCustomer=(customer:string)=>{
    return customer.toLowerCase() === 'lattice semiconductor'  ? true:false;
}
export {getCustomerAccess,userValidation,filterCustomerList,isLatticeCustomer};