import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { passwordActions } from '../../actions/password.actions';
import { validateAll, validateData } from '../login/validation';


const ForgotPassword = () => {
    const navigate = useNavigate();
    const [userEmail, setEmail] = useState("")
    const [error, setErrors] = useState({ userEmail: "" });
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setEmail(value);
        let errorObj: any = error;
        validateData(name, value, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let errorObj = error;
        const isValid = validateAll({ userEmail: userEmail }, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
        if (isValid) {
            dispatch(passwordActions.forgotUser({ userEmail: userEmail }))
        }
    }
    const onCancel = () => {
        navigate("/signin");
    }

    return (
        <div className="form-img py-5">
            <div className='card add-form-cont col-md-6 p-4 '>
                <h3 className="d-flex justify-content-center mt-4">Forgot Password</h3>
                <div className="col-md-12 mt-3">
                    <div className="p-3">
                        <div className="form-group">
                            <label className='form-label'>Email Address</label>
                            <input type="email" name="userEmail" value={userEmail} onChange={handleChange} placeholder="Enter email address" className={'form-control' + (error.userEmail ? ' is-invalid' : '')} />
                            {error.userEmail &&
                                <div className="invalid-feedback">{error.userEmail}</div>
                            }
                        </div>
                        <div className="button-group mt-5 d-flex justify-content-center">
                            <button className="btn btn-outline-primary mx-3" onClick={onCancel}>Cancel</button>
                            <button className="btn btn-primary" onClick={handleSubmit}>Reset Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ForgotPassword }
