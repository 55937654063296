export const isLogin = () => {
    if (localStorage.getItem('user')) return true;
    return false;
}

export const getUserToken=()=>{
    let user:any = localStorage.getItem('user');
    if(user){
       let userState= JSON.parse(user);
       return userState;
    }else{
        return "";
    }
}

export  const getAccess=(name:any)=>{
    let user:any = localStorage.getItem('user');
    if(user){
       return true;
    }else{
        return false;
    } 
}