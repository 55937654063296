import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/user.actions';
import { Select } from '../common/dropdown/Select';
import { UserObj, roleList, status } from '../../Utils/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import './AddUserPage.scss';
import { validateAll, validateData } from '../login/validation';
import { filterCustomerList, getCustomerAccess, userValidation } from './UserHelper';
// import { getUserInfo, getUserRole } from '../../Utils/role-access';

const AddUserPage = () => {
    const { state }:any = useLocation();
    const {id}=state ? state:"";
    const { customerNames, userList } = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    const [customerList, setCustomerList]=useState([]);
    const [user, setUser] = useState(JSON.parse(JSON.stringify(UserObj)));
    const [error, setErrors] = useState(JSON.parse(JSON.stringify(UserObj)));
    // let role = getUserRole();
    const dispatch = useDispatch();
    useEffect(()=>{
        setCustomerList(customerNames);
    },[customerNames]);

    useEffect(() =>{
        dispatch(userActions.getCustomerNames());
    },[dispatch]);

    // useEffect(() => {
    //     if (role !== "lattice_admin") {
    //         let userInfo: any = getUserInfo();
    //         let customerId = userInfo.customerId;
    //         setUser((user: any) => ({ ...user, customerId: customerId, role: "customer_user" }));
    //     }
    // }, [role]);

    useEffect(() => {
        if (id && userList.length) {
            let data = userList.find((row: any) => row.id === Number(id));
            setUser(data);
        }
    }, [id, userList]);

    function handleChange(e: any) {
        const { name, value } = e.target;
        setUser((user: any) => ({ ...user, [name]: value }));
        let errorObj: any = error;
        validateData(name, value, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
        if (name === 'role' && value.includes("lattice")) {
            setCustomerList(customerNames);
            setLatticeUser("Lattice Semiconductor");
        } else if (name === 'role') {
            resetCustomer();
            setCustomerList(filterCustomerList(customerList));
        }
        if (name === 'customerId') {
            handleCustomerName(value);
        }

    }

    function handleSubmit(e: any) {
        e.preventDefault();
        let errorObj = error;
        const isValid = validateAll(user, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
        if (isValid) {
            if(id){
                dispatch(userActions.updateUser(user));
            }else{
                dispatch(userActions.addUser(user));
            }
        }
    }
    const handleCustomerName = (id: string) => {
        let obj = customerNames.find((row: any) => {
            return row.id === Number(id);
        });
        let newState = user;
        newState.customerId = (obj && obj.id) ? obj.id : "";
        newState.customerName = (obj && obj.customerName) ? obj.customerName : "";
        setUser((user: any) => ({ ...user, newState }));
    }

    const setLatticeUser=(name:string)=>{
        let obj = customerNames.find((row: any) => {
            return row.customerName.toLowerCase() === name.toLowerCase();
        });
        setUser((user:any)=>({...user,customerId:obj.id,customerName:obj.customerName}));
    }

    const resetCustomer = () => {
        let newState = user;
        newState.customerId = "";
        setUser((user: any) => ({ ...user, newState }));
    }

    const onCancel = () => {
        let url= id ? "/viewUsers":"/search";
        navigate(url);
    }

    return (
        <div className="form-img py-5">
            <div className='card add-form-cont col-md-6 p-4 '>
                <h2 className="d-flex justify-content-center">{id ? "Edit / Modify User":"Register New User"}</h2>
                <form name="form" className='mt-3'>
                        {!id &&  <div className="form-group">
                        <label className='form-label'>User Type</label>
                        <Select name="role" label="role" defaultLabel="Select User Type" labelValue="name" items={roleList} value={user.role} onChange={handleChange} className={'form-control' + (error.role ? ' is-invalid' : '')} disabled={userValidation()} />
                        {error.role &&
                            <div className="invalid-feedback">{error.role}</div>
                        }
                    </div>}
                    <div className="form-group">
                        <label className='form-label'>User Full Name</label>
                        <input type="text" name="userName" value={user.userName} onChange={handleChange} className={'form-control' + (error.userName ? ' is-invalid' : '')} />
                        {error.userName &&
                            <div className="invalid-feedback">{error.userName}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Email Address</label>
                        <input type="email" name="userEmail" value={user.userEmail} onChange={handleChange} className={'form-control' + (error.userEmail ? ' is-invalid' : '')} />
                        {error.userEmail &&
                            <div className="invalid-feedback">{error.userEmail}</div>
                        }
                    </div>
                    {id &&  <div className="form-group">
                        <label className='form-label'>Customer Name</label>
                        <input type="text" name="customerName" value={user.customerName || ''} onChange={handleChange} className={'form-control' + (error.customerName ? ' is-invalid' : '')} disabled={getCustomerAccess(user.role) || id} />
                        {error.customerName &&
                            <div className="invalid-feedback">{error.customerName}</div>
                        }
                    </div> }

                    {!id && <div className="form-group">
                        <label className='form-label'>Customer Name</label>
                        <Select name="customerId" label="customerName" defaultLabel="Select Customer Name" labelValue="id" items={customerList} value={user.customerId} onChange={handleChange} className={'form-control' + (error.customerId ? ' is-invalid' : '')} disabled={getCustomerAccess(user.role) || id} />
                        {error.customerId &&
                            <div className="invalid-feedback">{error.customerId}</div>
                        }
                    </div>}

                    <div className="form-group">
                        <label className='form-label'>Status</label>
                        <Select name="status" label="label" defaultLabel="Select Status" labelValue="status" items={status} value={user.status} onChange={handleChange} className={'form-control' + (error.status ? ' is-invalid' : '')} />
                        {error.status &&
                            <div className="invalid-feedback">{error.status}</div>
                        }
                    </div>
                    <div className="button-group mt-5 d-flex justify-content-center">
                        <button className="btn btn-outline-primary me-4" onClick={onCancel}>
                            Cancel
                        </button>                           
                        <button className="btn btn-primary" onClick={handleSubmit}>
                           {user.id ? "Update":"Register"} 
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}


export { AddUserPage }