import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../actions/user.actions';
import { RegisterObj } from '../../Utils/constants';
// import { getCustomerName, getUserName } from '../../Utils/helpers';
import { validateAll, validateData } from '../login/validation';

const RegistrationPage = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(JSON.stringify(RegisterObj)));
    const [error, setErrors] = useState(JSON.parse(JSON.stringify(RegisterObj)));
    const dispatch = useDispatch();


    function handleChange(e: any) {
        const { name, value } = e.target;
        setUser((user: any) => ({ ...user, [name]: value }));
        let errorObj: any = error;
        validateData(name, value, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        let errorObj = error;
        const isValid = validateAll(user, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
        if (isValid) {
            dispatch(userActions.saveSelfUser(user));
        }
    }
    const onCancel = () => {
        navigate("/signin");
    }

    return (
        <div className="form-img py-5">
            <div className='card add-form-cont col-md-6 p-4 '>
                <h2 className="d-flex justify-content-center">Register User</h2>
                <form name="form" className='mt-3'>
                    {/* <div className="form-group">
                        <label className='form-label'>User Type</label>
                        <input type="text" name="role" value="Customer User" className='form-control' disabled={true} />
                    </div> */}
                    <div className="form-group">
                        <label className='form-label'>Email Address</label>
                        <input type="email" name="userEmail" value={user.userEmail} onChange={handleChange} className={'form-control' + (error.userEmail ? ' is-invalid' : '')} />
                        {error.userEmail &&
                            <div className="invalid-feedback">{error.userEmail}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>User Full Name</label>
                        <input type="text" name="userName" value={user.userName} onChange={handleChange} className={'form-control' + (error.userName ? ' is-invalid' : '')} />
                        {error.userName &&
                            <div className="invalid-feedback">{error.userName}</div>
                        }
                    </div>
                    <div className="form-group">
                        {/* <label className='form-label'>Customer Name</label>
                        <input type="text" name="customerName" value={user.customerName} onChange={handleChange} className={`form-control ${(error.customerName ? ' is-invalid' : (validation.customerName ? 'is-valid' : ''))}`}  />
                        {error.customerName &&
                            <div className="invalid-feedback">{error.customerName}</div>
                        } */}
                        <label className='form-label'>Company Name</label>
                        <input type="text" name="customerName" value={user.customerName} onChange={handleChange} className={'form-control' + (error.customerName ? ' is-invalid' : '')} />
                        {error.customerName &&
                            <div className="invalid-feedback">{error.customerName}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Status</label>
                        <input type="text" name="status" value="Active" className='form-control' disabled={true} />
                    </div>
                    <div className="button-group mt-5 d-flex justify-content-center">
                        <button className="btn btn-outline-primary me-4" onClick={onCancel}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={handleSubmit}>Register</button>
                    </div>

                </form>
            </div>
        </div>
    );
}

export { RegistrationPage }