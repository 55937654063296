const latticeAdmin = ['view-add-user-customer'];
const latticeUser = ['search', 'onboard'];
const customerUser = ['search', 'onboard'];

const getUserRole = () => {
    const userInfo = localStorage.getItem('user');
    let role = userInfo ? JSON.parse(userInfo).role : "";
    return role;
}


const getUserInfo = () => {
    const userInfo = localStorage.getItem('user');
    let data = userInfo ? JSON.parse(userInfo) : {};
    return data;
}

const getRoleAccess = (access: any) => {
    const role = getUserRole();
    if (role === 'lattice_admin') {
        if (latticeAdmin.includes(access)) {
            return true;
        } else {
            return false;
        }
    } else if (role === 'lattice_user' || 'customer_user') {
        if (latticeUser.includes(access) || customerUser.includes(access)) {
            return true;
        } else {
            return false;
        }
    }
}

const adminAccess = ['addCustomer', 'addUser', 'viewUsers', 'viewCustomers', 'search', 'viewLoginReport', 'viewScanReport', 'viewScanReportDetail'];
const userAccess = ['search']

const getLandPageAccess = (menu: any) => {
    let role = getUserRole();
    if (role === 'lattice_admin' && adminAccess.includes(menu)) {
        return true;
    } else if (role === 'lattice_user' || role === 'customer_user') {
        if (userAccess.includes(menu)) {
            return true;
        }
    } else {
        return false;
    }
}




export { getRoleAccess, getLandPageAccess, getUserRole, getUserInfo }