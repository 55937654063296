import success from '../../assets/images/ic_success.svg';
import { Link, useLocation } from 'react-router-dom';
import { getRoleDisplayName, toPascalCase } from '../../Utils/helpers';

const OnboardSuccessPage = () => {
    const {state}:any = useLocation();
    const {type,data}=state;
    return (
        <div className="form-img py-5">
            <div className='card col-lg-5 offset-lg-4 p-4'>
                <div className="d-flex justify-content-center">
                    <img src={success} height={100} width={100} alt=""/>
                </div>
                <div className='text-center'>
                    <h2 className='text-success mt-5'>Congratulations</h2>
                    {type==='user' && <span>{ `Default password has been sent to your registered email address.`}{toPascalCase(data.userName)} {getRoleDisplayName(data.role)} account.</span>}
                    {type==='customer' && <span>Successfully registered customer: {data.customerName}</span>}
                    <div>
                        <Link to="/search" className='btn btn-primary col-md-12 mt-5'>Go to home Page</Link>
                        <Link to={(type==='user') ? '/addUser':'/addCustomer'} className='btn btn-outline-primary col-md-12 mt-4'>Register  another {type}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { OnboardSuccessPage }

