import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { eventHandler } from '../../App';
import { isLogin } from '../../middleware/Auth';
function AccessDenied() {
    const navigation = useNavigate();
    const [sendRequest, setSendRequest] = useState(false);

    useEffect(() => {
        if (sendRequest) {
            if (isLogin()) {
                navigation("/search");
            } else {
                navigation("/signin");
                window.removeEventListener("beforeunload", eventHandler);
                window.location.reload();
            }
        }
    }, [navigation, sendRequest]);

    // const goBack = () => {
    //     navigation("/signin");
    // };
    return (
        <div className='text-danger text-center m-5'>
            <h1>Access Denied !</h1>
            <h6 className='m-5'>You don't have permission to access this page</h6>
            <button onClick={() => setSendRequest(true)} className="btn btn-primary btn-sm me-3">
                Back
            </button>
        </div>
    );
}
export { AccessDenied };
