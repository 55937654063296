
import './Style.css';
import SuccessIcon from '../../../assets/images/ic_success.svg';
declare var bootbox: any;

const SuccessModal = (message: string) => {
    const dialog =bootbox.dialog({
        message: `<img class="rounded mx-auto d-block" src=`+SuccessIcon+` alt="success"/>
                  <p class="text-center mt-3">`+message+`</p> `,
        closeButton: false
    });
    setTimeout(function(){
        dialog.modal('hide');
    }, 3000);
};
export {SuccessModal};