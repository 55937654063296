import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/user.actions';
import { Select } from '../common/dropdown/Select';
import { CustomerObj, status } from '../../Utils/constants';
import { useLocation, useNavigate} from 'react-router-dom';
import './AddCustomerPage.scss';
import { validateAll, validateData } from '../login/validation';

const AddCustomerPage = () => {
    const { customerList } = useSelector((state: any) => state.user);
    const { state }:any = useLocation();
    const {id}=state ? state:"";
    const navigate = useNavigate();
    const [customer, setCustomer] = useState(JSON.parse(JSON.stringify(CustomerObj)));
    const [error, setErrors] = useState(JSON.parse(JSON.stringify(CustomerObj)));
    const dispatch = useDispatch();
    useEffect(() =>{
          if(id){
              let data = customerList.find((row:any)=>row.id=== Number(id));
              setCustomer(data);
          }

    },[id,customerList]);

    function handleChange(e: any) {
        const { name, value } = e.target;
        setCustomer((customer:any) => ({ ...customer, [name]: value }));
        let errorObj: any = error;
        validateData(name, value, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        let errorObj = error;
        const isValid = validateAll(customer, errorObj);
        setErrors((state: any) => ({ ...state, errorObj }));
        if (isValid) {
            if(id){
                dispatch(userActions.updateCustomer(customer));
            }else{
                dispatch(userActions.addCustomer(customer));
            }
        }
    }
    const onCancel = () => {
        let url= id ? "/viewCustomers":"/search";
        navigate(url);
    }

    return (
        <div className="form-img py-5">
            <div className='card add-form-cont col-md-6 p-4 '>
                <h2 className="d-flex justify-content-center">{id ? "Edit / Modify Customer":"Register New Customer"}</h2>
                <form name="form" className='mt-3'>
                    <div className="form-group">
                        <label className='form-label'>Customer Name</label>
                        <input type="text" name="customerName" value={customer.customerName} onChange={handleChange} className={'form-control' + (error.customerName ? ' is-invalid' : '')} disabled={id}/>
                        {error.customerName &&
                            <div className="invalid-feedback">{error.customerName}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Customer Address 1</label>
                        <input type="text" name="address1" value={customer.address1} onChange={handleChange} className={'form-control' + (error.address1 ? ' is-invalid' : '')} />
                        {error.address1 &&
                            <div className="invalid-feedback">{error.address1}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Customer Address 2</label>
                        <input type="text" name="address2" value={customer.address2} onChange={handleChange} className={'form-control' + (error.address2 ? ' is-invalid' : '')} />
                        {error.address2 &&
                            <div className="invalid-feedback">{error.address2}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Customer Address 3</label>
                        <input type="text" name="address3" value={customer.address3} onChange={handleChange} className={'form-control' + (error.address3 ? ' is-invalid' : '')} />
                        {error.address3 &&
                            <div className="invalid-feedback">{error.address3}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Country</label>
                        <input type="text" name="country" value={customer.country} onChange={handleChange} className={'form-control' + (error.country ? ' is-invalid' : '')} />
                        {error.country &&
                            <div className="invalid-feedback">{error.country}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>State</label>
                        <input type="text" name="state" value={customer.state} onChange={handleChange} className={'form-control' + (error.state ? ' is-invalid' : '')} />
                        {error.state &&
                            <div className="invalid-feedback">{error.state}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Zip Code/ Postal Code</label>
                        <input type="number" name="zipcode" value={customer.zipcode} onChange={handleChange} className={'form-control' + (error.zipcode ? ' is-invalid' : '')} />
                        {error.zipcode &&
                            <div className="invalid-feedback">{error.zipcode}</div>
                        }
                    </div>
                    <div className="form-group">
                        <label className='form-label'>Status</label>
                        <Select name="status" label="label" defaultLabel="Select Status" labelValue="status" items={status} value={customer.status} onChange={handleChange} className={'form-control' + (error.status ? ' is-invalid' : '')} />
                        {error.status &&
                            <div className="invalid-feedback">{error.status}</div>
                        }
                    </div>
                    <div className="button-group mt-5 d-flex justify-content-center">
                        <button className="btn btn-outline-primary me-4" onClick={onCancel}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={handleSubmit}>
                            {id ? "Update":"Register"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export { AddCustomerPage }