import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/user.actions';
import { between, getDate, sortAsc } from '../../Utils/helpers';
import { Pagination } from '../common/pagination/Pagination';
import sortIcon from '../../assets/images/ic-sort.svg';
import './CustomerListPage.scss';
import editIicon from '../../assets/images/ic-edit.svg';
import { history } from '../../Utils/history';
import { isLatticeCustomer } from '../users/UserHelper';
const CustomerListPage = () => {
    const { customerList } = useSelector((state: any) => state.user);
    const [customers, setCustomers] = useState<Array<any>>([]);
    const [initialPage, setInitialPage] = useState(0);
    const [sort, setSort] = useState(false);
    const [listData, setListData] = useState<Array<any>>([]);
    const [sortFlag, setSortFlag] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.getCustomers());
    }, [dispatch]);

    // useEffect(() => {
    //     setCustomers(customerList);
    // }, [customerList]);

    useEffect(() => {
        setCustomers(customerList.slice(0, 10));
        setInitialPage(0);
    }, [customerList]);

    const onPageChange = (e: any) => {
        let { selected } = e;
        setInitialPage(selected);
        if (selected === 0) {
            if (sortFlag) {
                setCustomers(listData.slice(0, 10));
            } else {
                setCustomers(customerList.slice(0, 10));
            }
        } else {
            let start = selected * 10;
            let end = (start+1) + 10;
            if (sortFlag) {
                let list: any = between(listData, start, end, false);
                setCustomers(list);
            } else {
                let list: any = between(customerList, start, end, false);
                setCustomers(list);
            }
        }
    }

    const handleEdit = (row: any) => {
        if (!isLatticeCustomer(row.customerName)) {
            history.push("/addCustomer", { id: row.id });
        }
    }

    const handleSort = () => {
        setSortFlag(true);
        setSort(!sort);
        let data: any = sortAsc([...customerList], 'customerName', sort);
        setListData(data);
        if (initialPage === 0) {
            // let list = data.slice(0,10);
            setCustomers(data.slice(0, 10));
        } else {
            let start = initialPage * 10;
            let end = (start+1) + 10;
            let list: any = between(data, start, end, false);
            setCustomers(list);
        }
    }

    return (
        <div className="table-cont">
            <div className="container-fluid">
                <h3 className='mt-3'>View / Modify Customers</h3>
                <div className="d-flex justify-content-center mt-4">
                    <table className="table maint-table">
                        <thead className="table-dark">
                            <tr>
                                <td>CUSTOMER<img src={sortIcon} className='sort-icon' alt="" onClick={handleSort}></img></td>
                                <td className='tablew100'>CUSTOMER ID</td>
                                <td>ADDRESS 1</td>
                                <td>ADDRESS 2</td>
                                <td>ADDRESS 3</td>
                                <td>COUNTRY</td>
                                <td>STATE</td>
                                <td>ZIPCODE</td>
                                <td>CREATED DATE</td>
                                <td>STATUS</td>
                                <td>ACTIONS</td>
                            </tr>
                        </thead>
                        <tbody>
                            {customers.length === 0 && <tr><td colSpan={7}><h6 className="text-center m-3">No Data Available</h6></td></tr>}
                            {customers.map((row: any, index: number) => {
                                return (
                                    <tr key={index} className="">
                                        <td> {row.customerName}</td>
                                        <td className='tablew100'> {row.id}</td>
                                        <td className='addressw'> {row.address1}</td>
                                        <td className='addressw'> {row.address2}</td>
                                        <td className='addressw'> {row.address3}</td>
                                        <td> {row.country}</td>
                                        <td> {row.state}</td>
                                        <td> {row.zipcode}</td>
                                        <td> {getDate(row.creationDate)}</td>
                                        <td>
                                            {row.status === 1 && <div className='btn btn-success btn-sm'>Active</div>}
                                            {row.status === 0 && <div className="btn btn-danger btn-sm">Inactive</div>}
                                        </td>
                                        <td><img className={isLatticeCustomer(row.customerName) ? "imgHover-disabled" : "imgHover"} src={editIicon} alt="edit" onClick={() => handleEdit(row)}></img></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pagination-cont mt-2">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            <Pagination pageCount={Math.ceil((customerList.length / 10))} handlePageClick={onPageChange} forcePage={initialPage} initialPage={initialPage}/>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}
export { CustomerListPage }
