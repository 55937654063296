import './SearchResultPage.scss';
import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions/user.actions';
import { genericIDHeadings, lotIdSearchHeadings } from '../../../Utils/constants';
import { generateKey, searchValidate } from '../../../Utils/helpers';
import { userConstants } from '../../../constants/user.constants';


import { ErrorModalSearch } from '../../common/confirm-modal/ErrorModalSearch';


// import { ErrorModal } from '../../common/confirm-modal/ErrorModal';

const SearchResultPage = forwardRef((props, ref) => {
    
    const { result } = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        getSearchResult(query: any, inputs : { inputs: any }) {
            if (searchValidate(query)) {
                ErrorModalSearch({ message: "Lot id not found" });
                dispatch({ type: userConstants.SEARCH_REQUEST });
            } else {
                dispatch(userActions.searchDevice( query, inputs ));
            }
        },
        getUnitCode(query: any, inputs : { inputs: any }) {
            dispatch(userActions.searchUnitCode( query,  inputs ));
        }
    }));
    useEffect(() => {
        dispatch({ type: userConstants.SEARCH_REQUEST });
    }, [dispatch]);

    
    return (
        <div className="table-cont py-4">
            <h3 className='my-2 text-center'>Product Information</h3>
            <table className="table maint-table search-cont">
                {/* <thead className="table-dark">
                    <tr>
                        <td>DESCRIPTION</td>
                        <td>DATA</td>
                    </tr>
                </thead> */}
                <tbody>
                    {Object.keys(result).length > 0 && result.searchType === "unitCode" ? <> {genericIDHeadings.map((row) => {
                        return (
                            <tr key={generateKey()}>
                                <td key={generateKey()}>{row.label}</td>
                                <td key={generateKey()}>{result[row.value]}</td>
                            </tr>
                        )
                    })
                    }</> : <>{Object.keys(result).length > 0 && lotIdSearchHeadings.map((row) => {
                        return (
                            <tr key={generateKey()}>
                                <td key={generateKey()}>{row.label}</td>
                                <td key={generateKey()}>{result[row.value]}</td>
                            </tr>
                        )
                    })
                    }</>}
                    {Object.keys(result).length === 0 && <tr><td colSpan={2} className='text-center border-right'>No Data Available</td></tr>}
                </tbody>
            </table>
        </div>
    )
})

export { SearchResultPage };