import React from 'react';
import './LocationModal.scss';
import './TnCStyle.css';

 
function LocationModal({ show, onCancel } : {show: any, onCancel: any}) {
    if (!show) return null;
 
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Location Access Required</h2>
                <p>
                    To continue, please enable location access.<br/>Please check your browser settings to enable location services.
                </p>
                <div className="modal-actions">
                    <button className="btn btn-primary" onClick={onCancel}>
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
}
 
export { LocationModal };