import './LoginErrorModal.css';
declare var bootbox: any;
 
const LoginErrorModal = (props: any) => {
    bootbox.confirm({
        // <div  class="status-cont text-center mb-4"><img class="rounded mx-auto d-block" width="72" src=`+ErrorIcon+` alt="success"/></div>
        title:"Reset Password",
        message: `<h6 class="text-secondary text-center m-0">`+props.message+`</h6>`,
        closeButton: false,
        buttons: {       
            confirm: {
                label: props.confirm,
                className: 'btn btn-primary'
            },
            cancel: {
                label: props.cancel,
                className: 'btn btn-outline-primary',
            }
        },
        callback: function (result: any) {
            props.onValueChange(result);
        }
    });
};
export {LoginErrorModal};